.support-ctn {
  height: 60vh;
}

.service-box-ctn {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  margin-top: 50px;
}

.service-box-wrapper a {
  text-decoration: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

.service-box-wrapper .service-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.09);
  padding: 20px 25px;
  margin: 10px 0;
}

.service-box-wrapper .service-box .service-box-icon {
  display: block;
  width: 80px;
  height: 90px;
  margin-right: 20px;
}
.service-box-wrapper .service-box .service-box-icon svg {
  display: block;
  width: 100%;
  height: 100%;
  color: #f5a075;
}

.service-box-wrapper .service-box .service-box-content h4 {
  font-weight: 700;
}

.service-box-wrapper .service-box .service-box-content p {
  margin-bottom: 0;
}
