.product-review-details-wrapper {
  background-color: white;
  border-radius: 20px;
  padding: 8px 15px;
  margin: 3rem 0;
}

.product-review-details-wrapper h2 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #313133;
}

.product-name-more {
  font-size: 1rem;
}

.product-review-details-ctn {
  display: flex;
  gap: 2rem;
}

.product-review-details-product-ctn .product-review-details-product-name {
  background-color: #f0f0f0;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 10px;
}

.product-review-details-product-ctn h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #f28c18;
  margin: 0 !important;
}
.product-review-details-product-ctn h6 {
  font-size: 1rem;
  font-weight: 500;
}

.product-review-details-user-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
}

.product-review-details-user-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-bottom: 1px solid #ece9e9 !important;
  padding: 10px 0;
}

.product-review-details-user-content p {
  font-size: 16px;
  margin: 0 !important;
}

.product-review-details-user-content a,
.review-date-ctn h4 {
  font-size: 14px !important;
  margin: 0;
}

.review-date-ctn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.no-review {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.review-date-ctn p {
  font-weight: 700;
}

@media (max-width: 850px) {
  .product-review-details-ctn {
    flex-direction: column;
  }
}
