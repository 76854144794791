#main-hero-section {
  height: 50vh;
  overflow: hidden;
  padding-top: 30px;
}

.carousel__wrapper {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.carousel__content {
  position: absolute;
  height: 100%;
  transition: all 3s ease-in-out;
  inset: 0px;
}

.carousel__wrapper .active {
  opacity: 1;
}

.carousel__wrapper .disabled {
  opacity: 0;
}

.carousel__content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel__wrapper .buttons-wrapper {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
}

.buttons-wrapper span {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
}

.buttons-wrapper .active {
  border: #dd4f05;
  background-color: #dd4f05;
}
.buttons-wrapper .disabled {
  border: #afadad;
}

#main-hero-section .container,
#carouselExampleSlidesOnly,
.carousel-inner,
.carousel-item,
.carousel-item img {
  height: 100%;
}

/* Features */

#features-wrap {
  padding: 0 0 50px;
}

#features-wrap .feature-card {
  width: 100%;
  background-color: #fdf2e7;
  border-radius: 4px;
  padding: 40px 25px;
  margin-bottom: 30px;
}

#features-wrap .feature-card .rspot-icon {
  margin-right: 10px;
}

#features-wrap .feature-card h2 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 20px;
  color: #282828;
}

#features-wrap .feature-card p {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  color: #282828;
}

.banner-wrap {
  margin-bottom: 30px !important;
}

/* Popular Categories */

#popular-cat {
  padding: 50px 0 0;
}

#popular-cat .cat-wrap {
  display: flex;
  /* flex-wrap: nowrap; */
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  justify-content: space-between;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  gap: 10px;
}

#popular-cat .cat-wrap::-webkit-scrollbar {
  height: 2px;
}

#popular-cat .cat-wrap a {
  text-decoration: none;
  color: #282828;
}

#popular-cat .cat-wrap .cat-box {
  display: flex;
  flex-direction: column;
  width: 140px !important;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 10px 0px;
  align-items: center;
}

#popular-cat .cat-wrap .cat-box:hover h3 {
  color: #dd4f05;
}

#popular-cat .cat-wrap .cat-box:hover img {
  transform: scale(1.15);
}

#popular-cat .cat-wrap .cat-box img {
  height: 120px;
  width: 90%;
  object-fit: cover;
  transition: transform 0.2s;
}

#popular-cat .cat-wrap .cat-box h3 {
  font-size: 0.9rem;
  margin: 10px 0 0;
  padding: 0;
}

/* Featured Product */
#featured-products {
  padding: 50px 0 0;
}

/* Banner area */
.banner-area {
  padding: 0 0 0;
  height: 400px;
  overflow: hidden;
}

/* RFQ area */
#rfq-list {
  padding: 50px 0 0;
}

#rfq-list .rfq-list-wrap {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  justify-content: space-between;
}

#rfq-list .rfq-list-wrap .rfq-list-box {
  width: 390px;
  padding: 20px;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: rgba(216, 227, 251, 0.22);
}

#rfq-list .rfq-list-wrap .rfq-list-box:hover {
  background-color: #fdf2e7;
}

#rfq-list .rfq-list-wrap .rfq-list-box h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 10px;
}

#rfq-list .rfq-list-wrap .rfq-list-box h3 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding: 0 0 25px;
}

#rfq-list .rfq-list-wrap .rfq-list-box p {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

#rfq-list .rfq-list-wrap .rfq-list-box p span {
  float: right;
}

#rfq-list .rfq-list-wrap .rfq-list-box p span a {
  color: #dd4f05;
}

::-webkit-scrollbar {
  width: 5px !important;
}

@media (max-width: 768px) {
  #popular-cat {
    padding: 20px 0 0;
  }
  #popular-cat .cat-wrap {
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.73rem;
    -webkit-overflow-scrolling: touch;
  }

  .banner-area {
    height: auto;
  }
  #main-hero-section {
    height: 40vh;
  }

  #popular-cat .cat-wrap .cat-box img {
    height: 110px;
  }
  #popular-cat .cat-wrap .cat-box h3 {
    text-align: center;
  }
}

@media (max-width: 450px) {
  #main-hero-section {
    height: 22vh;
  }
}
