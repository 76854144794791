h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
p {
  color: #282828;
}

a {
  text-decoration: none !important;
}

.dim-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2000;
}

@media (min-width: 1200px) {
  .container {
    width: 1250px !important;
  }
}

* {
  font-family: "Mulish", sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  background-color: #f9f9f9;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input,
select,
textarea {
  box-shadow: none !important;
  outline: 0px !important;
}

button {
  box-shadow: none !important;
  outline: 0px !important;
}

input {
  height: 43px;
  box-shadow: none !important;
  outline: 0px !important;
  padding: 0;
}

select {
  height: 42px !important;
  box-shadow: none !important;
  outline: 0px !important;
}

/* Section head */
.section-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.section-head h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: #dc4d04;
}

.section-head p {
  font-size: 0.85rem;
  margin: 0;
  padding: 0;
}

.section-head p a {
  color: #282828;
}

.section-head .scroll-icon {
  cursor: pointer;
}

/* Product Card */

.product-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-wrap::-webkit-scrollbar {
  display: none;
}

.main-container {
  position: relative;
}

.p-container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
}

.p-container:after {
  content: "";
  position: absolute;
  right: 0px;
  height: 100%;
  width: 10%;
  pointer-events: none;
}

.p-container::-webkit-scrollbar {
  display: none;
}

.p-container .link {
  text-decoration: none;
}

.product-card {
  width: 230px;
  background-color: #ffffff;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 30px;
  flex: 0 0 auto;
  cursor: pointer;
  border-radius: 6px;
}

.product-card:hover {
  background-color: #ffffff;
  box-shadow: 0 10px 40px rgb(0 0 0 / 8%);
}

.product-card:hover .product-card-info .product-name {
  text-decoration: underline;
  color: #dc4d04;
}

.product-card:hover .product-card-img img {
  transform: scale(1.15);
}

.product-card .product-card-img {
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
}

.product-card .product-card-img .badge {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 10px;
  font-weight: 400;
  background-color: #1c9b5e !important;
  padding: 5px 10px !important;
}

.product-card .product-card-img img {
  width: 100%;
  height: 190px;
  transition: transform 0.2s;
  object-fit: cover !important;
  /* border-radius: 6px 6px 0 0; */
}

.product-card .product-card-info {
  padding: 0 15px 15px;
}

.product-card .product-card-info .black__friday {
  background-color: #dc4d04;
  max-width: fit-content;
  border-radius: 5px;
  color: #f9f9f9;
  font-weight: 700;
  padding: 1px 4px;
  font-size: 10px;
}

.product-card .product-card-info .discount__ctn {
  display: flex;
  gap: 1rem;
}

.product-card .product-card-info .discount__ctn .p-currency {
  opacity: 0.3;
  text-decoration: line-through;
  font-size: 14px;
}

.product-card .product-card-info .discount__ctn .discount__per {
  width: max-content;
  padding: 1px 4px;
  background-color: #dc4d04;
  color: #f9f9f9;
  font-size: 13px;
}

.product-card .product-card-info .seller-name {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card .product-card-info .product-name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-card .product-card-info .product-name-long {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 10px;
}

.product-card .product-card-info .product-price {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.product-card .product-card-info .product-price .p-currency {
  font-size: 13px;
  color: #5c5c5c;
  font-weight: 300;
}

.product-card .product-card-info .product-price .p-unit {
  font-size: 13px;
  color: #5c5c5c;
  font-weight: 300;
}

.product-card .product-card-info .product-spec-sum {
  font-size: 13.5px;
  margin: 0;
  padding: 0 0 10px;
}

.product-card .product-card-info .product-spec-sum span {
  color: #5c5c5c;
}

.product-card .product-card-info .product-link {
  font-size: 14px;
  color: #dc4d04;
}

/* Product Category */

.product-cat-list {
  width: 100%;
  display: flex;
  list-style: none;
  overflow: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 50px;
}

.product-cat-list::-webkit-scrollbar {
  display: none;
}

.product-cat-list .product-cat {
  flex-shrink: 0;
  width: fit-content;
  scroll-snap-align: start;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 50px;
  font-size: 0.9rem;
  padding: 10px 20px;
  display: block;
  color: #282828 !important;
}

.product-cat-list .product-cat li {
  color: #282828 !important;
}

.product-cat-list .product-cat.active {
  border-color: #dc4d04;
  font-weight: 600;
}

.product-cat-list .active-product {
  flex-shrink: 0;
  width: fit-content;
  scroll-snap-align: start;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 50px;
  font-size: 0.9rem;
  padding: 10px 20px;
  display: block;
  color: #282828 !important;
  border-color: #dc4d04;
  font-weight: 600;
}

.product-cat-list .product-cat:hover {
  border-color: #dc4d04;
}

.mobile-carousel-nav {
  display: none;
}

.carousel-nav {
  cursor: pointer;
}

.bank-ctn {
  display: flex;
  flex-direction: column;
}

.bank-details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
}

.bank-details h1 {
  color: #22945d;
  font-weight: 700;
  margin: 50px 0;
}

.bank-details p:first-child {
  font-weight: 700 !important;
}

.bank-details ul {
  text-decoration: none;
  list-style: none;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bank-details ul li {
  width: 100%;
  text-align: left;
}

.bank-details ul li span {
  font-weight: 900;
}

.bank-details .notice .danger {
  color: #dc4d04;
  font-weight: 900;
}

.bank-details .title {
  font-weight: 800;
}

.ant-modal-footer button {
  display: none;
}

@media (max-width: 768px) {
  .product-wrap {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }
  .product-card {
    width: 160px;
    min-width: 150px;
  }
  .product-card .product-card-img img {
    height: 150px;
  }
}

@media (max-width: 1200px) {
  .product-wrap {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-carousel-nav {
    display: block;
  }
}

select {
  height: 45px;
}

@media (max-width: 510px) {
  .section-head {
    margin-bottom: 10px;
  }

  .section-head i {
    display: none;
  }

  .section-head h2 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #dc4d04;
  }
  .p-container {
    justify-content: center;
  }

  .product-card {
    width: 46%;
    min-width: 150px;
  }
  .product-card .product-card-img img {
    height: 140px;
  }
  .product-card .product-card-info .seller-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 4px;
    font-size: 12px;
  }

  .product-card .product-card-info .product-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  .product-card .product-card-info .product-price {
    font-size: 14px;
  }
  .product-card .product-card-info .product-price .p-currency {
    font-size: 10px;
  }
}
