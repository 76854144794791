.checkout-ctn {
  background-color: rgb(241, 242, 244);
  position: relative;
}

.cheeck {
  padding: 10px 0;
}

.checkout-wrap {
  margin: 60px 0;
}

.over-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04) !important;
  padding: 20px;
  background-color: #fff;
  margin: 10px;
}

.over-card .user__title {
  font-size: 20px !important;
  font-weight: 700;
}

.user-profile-ctn h5 {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
}

.user-profile-ctn .user-details-ctn {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.user-details-ctn .user-name {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.user-name p,
.user-address p {
  font-size: 13px;
  margin: 0;
}

.user-name input {
  border-radius: 8px;
  border: 1px solid #dddddd;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 13px;
  width: 300px;
  height: 40px;
  margin: 6px 0;
}

.user-name input:focus {
  border: 1px solid #f57535;
}

.user-name p:first-child {
  font-weight: 800;
  margin-right: 10px;
}

.user-ctn button {
  outline: none;
  border: none;
  background-color: transparent;
  color: rgb(42, 132, 243);
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease-out;
  cursor: pointer;
  padding: 0;
  margin-top: 15px;
}

.user-ctn button:hover {
  text-decoration: underline;
}

.user-product-ctn {
  display: flex;
  flex-direction: column;
}

.user-product-wrap {
  display: flex;
  margin-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.04) !important;
}
.user-product-details {
  margin: 5px 10px;
}

.user-product-details p {
  font-size: 10px;
  font-weight: 400;
  margin: 0;
}

.user-product-details p:last-child {
  font-weight: 700;
}

.user-product-img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.user-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-product-name {
  width: 100%;
}

.user-product-name .product-name p {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0 4px;
}

.user-product-name .product-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-product-name .product-amount p:first-child {
  font-size: 14px;
  font-weight: 800;
}

.user-product-name .product-count p {
  font-weight: 400;
  color: #8e8c8c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-product-name .qty-input-ctn {
  margin: 5px 10px;
  border: none !important;
}

.user-product-name .qty-input-ctn input {
  width: 68px;
  border: 1px solid #c8c7c7;
  padding: 2px 8px;
  font-size: 10px;
  border-radius: 6px;
  height: 25px;
  margin-right: 5px;
}

.user-product-name .qty-input-ctn button {
  outline: none;
  border: none;
  font-size: 10px;
  padding: 4px 8px;
  background-color: #f57535;
  border-radius: 6px;
}

.user-product-name .product-count div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 1px solid #c8c7c7;
  margin: 5px 10px;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  position: relative;
}

.user-product-name .product-count div:hover {
  border: 1px solid #f57535;
}

.user-product-name .product-count div p {
  margin: 0;
  font-size: 10px !important;
  color: #141414;
}

.user-product-name .product-count div svg {
  color: #000;
  margin: 0 0 0 5px;
}

.user-product-name .product-count div ul {
  position: absolute;
  top: -2px;
  right: -1px;
  list-style: none;
  border: 1px solid #c8c7c7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px;
  background-color: #fff;
  width: 68px;
  border-radius: 6px;
  z-index: 1;
}

.user-product-name .product-count div ul li {
  font-weight: 700;
  font-size: 10px;
  padding: 4px 8px;
  width: 100%;
  transition: all 0.3s ease-out;
}

.user-product-name .product-count div ul li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.user-product-name .product-count div ul li:last-child {
  border-top: 1px solid #c8c7c7;
}

.user-product-name .product-count svg {
  width: 14px;
  margin: 0 10px;
  color: red;
  cursor: pointer;
}

.user-product-name .product-count p span {
  margin: 0 10px;
  background-color: #f4f2f2;
  width: 24px;
  height: 24px;
  display: block;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.4s ease-out;
}

.user-product-name .product-count p span:hover {
  color: #044b97;
}

.cart-ctn h5 {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

.cart-ctn .cart-cost h6 {
  font-size: 16px;
  font-weight: 900;
  margin-top: 20px;
}

.cart-cost .cart-wrap,
.total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-wrap p,
.total-cost p {
  font-size: 14px;
  font-weight: 600;
}

.total-cost {
  margin: 20px 0;
  border-bottom: 1px solid #f4f2f2;
}

.total-cost h6 {
  font-size: 14px;
  font-weight: 800;
}

.cart-ctn button {
  width: 100%;
  border: none;
  border-radius: 15px;
  padding: 8px 0;
  color: #fff;
  background-color: #f57535;
  transition: all 0.4s ease-out;
  font-size: 14px;
  margin-bottom: 10px;
}

.cart-ctn button:hover {
  background-color: #e45e1b;
}

.terms {
  font-size: 12px;
  text-align: center;
  color: #8e8c8c;
  width: 95%;
}

.terms span {
  color: #0876ec;
  font-weight: 600;
}

.shipping-form {
  padding: 20px 15px;
}

.address-ctn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.address-ctn input,
.address-ctn select {
  border-radius: 8px;
  border: 1px solid #dddddd;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
  width: 100%;
}

.address-ctn input:focus,
.address-ctn select:focus {
  border: 1px solid #f57535;
}

.shipping-form button {
  width: 100%;
  border-radius: 8px !important;
}

.shipping-form button:hover {
  background-color: #f57535;
}

.logistic-ctn {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.logistic-ctn p {
  font-size: 14px;
}

.tofaButton {
  max-width: max-content !important;
  padding: 4px 6px;
  background-color: #fff !important;
  color: #0876ec !important;
  text-decoration: underline;
  font-weight: bold;
}

#add_cart_button {
  background-color: #e45e1b;
  color: #fff;
  width: 80%;
}

.grand__total {
  display: flex;
  justify-content: space-between;
}

.grand__total h6,
.grand__total h5 {
  font-weight: 900;
}

.product-count-aliexpress {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 0 6px;
}

.product-count-aliexpress button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgb(233, 229, 229);
  color: #282828;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
}

.product-count-aliexpress button:hover {
  color: #f26819;
}

.product-count-aliexpress button svg {
  width: 14px;
}

.product-count-aliexpress .quanity {
  font-size: 14px;
}

.rate__currency {
  font-size: 12px;
  color: #8e8c8c;
}

@media only screen and (min-width: 46.875em) {
  .checkout-wrap {
    padding: 0 30px;
  }
  .user-profile-ctn .user-details-ctn {
    flex-direction: row;
    align-items: center;
  }

  .user-details-ctn .user-name {
    flex-direction: row;
    align-items: center;
  }
}
