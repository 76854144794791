.help-center-title {
  display: block;
  margin-top: 20px;
  background-color: #ffffff;
  width: 100%;
  padding: 50px 20px;
}

.help-center-title h2 {
  font-weight: 22px;
  font-weight: 700;
}

.help-center-title p {
  font-weight: 16px;
  color: #dd4f05;
}

.help-center-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.hc-content {
  display: grid;
  grid-template-columns: minmax(6rem, 1fr) repeat(8, minmax(1rem, 1fr)) minmax(
      6rem,
      1fr
    );
  margin: 2px 0;
}

.hc-content .hc-search-ctn {
  grid-column: 2/10;
  grid-row: 1/2;
  margin-bottom: 10px;
}

.hc-content .hc-search-ctn .search-issues {
  width: 100% !important;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.08);
}

.hc-content .hc-title-ctn {
  grid-column: 2/10;
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.08);
  margin-bottom: 10px;
}

.hc-content .hc-title-ctn .hc-title,
.hc-content .hc-title-ctn i {
  font-size: 16px;
}

.hc-content .hc-paragraph-ctn-hide {
  display: none;
}

.hc-content .hc-paragraph-ctn-show {
  grid-column: 2/10;
  grid-row: 2/3;
  padding-left: 20px;
}

.hc-content .hc-paragraph-ctn-show p {
  font-size: 12px;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

@media (max-width: 770px) {
  .help-center-title {
    padding: 30px 20px;
  }
  .help-center-title h2 {
    font-weight: 18px;
    font-weight: 700;
  }

  .help-center-title p {
    font-weight: 14px;
    color: #dd4f05;
  }
  .hc-content .hc-title-ctn {
    grid-column: 1/11;
  }
  .hc-content .hc-paragraph-ctn-show {
    grid-column: 1/11;
  }

  .hc-content .hc-title-ctn .hc-title,
  .hc-content .hc-title-ctn i {
    font-size: 14px;
  }

  .hc-content .hc-paragraph-ctn-show p {
    font-size: 10px;
  }
}
