.terms_ctn h1 {
  font-weight: 900;
  font-size: 20px;
  color: #000;
}

.terms_ctn h5 {
  font-size: 16px;
  font-weight: 700;
}

.terms_ctn h6 {
  color: rgb(164, 162, 162) d;
  font-weight: bold;
}

.terms_ctn .bold_span {
  font-weight: bold;
}

.terms_ctn ul {
  list-style: decimal;
}

.terns h5 {
  font-weight: 900 !important;
  font-size: 18px;
}

.terms_ctn .list___items {
  list-style: disc;
}
