.satisfaction-container {
  text-align: center;
  margin-top: 20px;
  padding: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.satisfied-button,
.unsatisfied-button {
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.satisfied-button:hover,
.unsatisfied-button:hover {
  background-color: aliceblue;
}

.satisfied-button.selected,
.unsatisfied-button.selected {
  background-color: #4caf50;
  color: white;
}

.unsatisfied-button.selected {
  background-color: #f44336;
}
