/* start List new product  */
.list-new-product-title-ctn h3 {
  font-size: 18px;
  font-weight: 700;
  color: #282828;
  line-height: 23px;
}

/* .list-new-product-title-ctn p {
    font-size: 11px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 13px;
    color: #dd4f05;
  } */

.featured-image {
  width: 70%;
}

.featured-image h6 {
  font-weight: 700;
}

.imp-info-text {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #dd4f05 !important;
  line-height: 13px;
}

/* start product description page */
.prod-descrption-ctn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.prod-description-img-wrapper {
  width: 201px;
  margin: 0 15px 0 0;
}

.prod-description-img-ctn {
  width: 100%;
  margin-bottom: 38px;
}

.prod-description-img-ctn .featured-img-ctn {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
}

.prod-description-img-ctn .featured-img-ctn:hover {
  border: 1px solid #dd4f05;
}

.prod-description-img-ctn .featured-img-ctn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prod-description-img-ctn .other-img-ctn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.prod-description-img-ctn .other-img-ctn .img-box {
  width: 40px;
  height: 35px;
  margin-right: 5px;
}

.prod-description-img-ctn .other-img-ctn .img-box:active {
  border: 2px solid #dd4f05;
}

.prod-description-img-ctn .other-img-ctn .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prod-descrption-ctn .product-info-wrapper {
  width: 370px;
  margin: 0 15px;
}

.prod-descrption-ctn .second-wrapper {
  margin-right: 0;
}

.prod-descrption-ctn .product-info-ctn {
  margin: 0 0 38px;
  width: 100%;
}

.prod-descrption-ctn .prod-title {
  font-size: 15px;
  font-weight: 700;
  color: #282828;
  line-height: 18.83px;
}

.prod-descrption-ctn .product-info-ctn .product-info-content {
  margin-top: 20px;
  width: 100%;
  height: 210px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0;
}

.product-info-content .prod-info {
  display: flex;
}

.product-info-content .prod-info .type {
  width: 120px;
  color: #5c5c5c;
  font-size: 12px;
  line-height: 15px;
}

.product-info-content .prod-info .value {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-left: 10px;
  line-height: 15px;
}

.prod-des-title-ctn {
  margin-bottom: 20px;
}

.prod-des-title-ctn h3 {
  color: #282828;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 3px solid #0064e5;
  padding-bottom: 5px;
  width: max-content;
}

.prod-des-title-ctn p {
  color: #1b2631;
  font-size: 12px;
  font-weight: 500;
}

.button-ctn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.button-ctn .edit-btn-ctn button {
  background-color: #dd4f05;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 4px;
}

.button-ctn .delete-btn-ctn button {
  background-color: transparent;
  color: #dd4f05;
  font-weight: 700;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 0 0 10px;
  border: 1px solid #dd4f05;
  border-radius: 4px;
}

/* end product description page */

@media (max-width: 1390px) {
  .prod-descrption-ctn {
    flex-direction: column;
    align-items: flex-start;
  }
  .prod-description-img-ctn .featured-img-ctn {
    width: 310px;
    height: 290px;
  }
  .prod-descrption-ctn .product-info-wrapper {
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .product-info-content .prod-info .type {
    font-size: 10px;
  }

  .product-info-content .prod-info .value {
    font-size: 10px;
  }

  .featured-image {
    width: 100%;
  }
}

@media (max-width: 590px) {
  .prod-descrption-ctn .product-info-wrapper {
    width: 400px;
  }
  .padding-10 {
    padding: 30px 10px;
  }
}

@media (max-width: 480px) {
  .prod-description-img-ctn .featured-img-ctn {
    width: 210px;
    height: 190px;
  }

  .prod-descrption-ctn .product-info-wrapper {
    width: 250px;
  }

  .prod-des-title-ctn p {
    font-size: 10px;
  }

  .button-ctn .edit-btn-ctn button {
    font-size: 12px;
    padding: 10px 20px;
    margin: 0 5px;
  }

  .button-ctn .delete-btn-ctn button {
    font-size: 12px;
    padding: 8px 15px;
    margin: 0 0 0 5px;
  }
}

@media (max-width: 400px) {
  .prod-descrption-ctn .product-info-wrapper {
    width: 100%;
  }

  .prod-description-img-wrapper {
    width: 100%;
    margin: 0 15px 0 0;
  }

  .prod-description-img-ctn .featured-img-ctn {
    width: 100%;
  }
}
