body {
  margin: 0;
  padding: 0;
  color: #282828;
  box-sizing: border-box;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 120px 1fr;
  grid-template-areas:
    "header"
    "main";
  height: 100vh;
}
.seller-order-info-container {
  grid-template-rows: 0px 1fr;
}

.header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 0 20px 0 55px;
  margin-top: 20px;
}

/* Make room for the menu icon on mobile */
.header__message h2 {
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}

.header__message .rfq-header {
  font-size: 14px;
}

.header__search {
  display: flex;
  align-items: center;
  width: 90%;
}

.header__search form {
  margin: 0;
}

.custom__search {
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.08);
}

.notify-wrap .icon-notification {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 15px;
}

.referral-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.referral-copy {
  color: #04a72d !important;
}

.referral-link {
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.referral-link svg {
  width: 12px;
  margin-left: 3px;
}

.share-referral {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px !important;
}

.share-referral button {
  background-color: transparent;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.share-referral svg {
  width: 20px;
}

.referral-link:hover,
.share-referral button:hover {
  color: #dc4d04;
}

.buyer {
  background-color: #0064e5;
}

.seller {
  background-color: #dc4d04;
}

.custom__search button {
  background-color: #fff;
  color: #5c5c5c;
  border: none;
  width: 20px;
}

.custom__search input {
  margin-left: 10px;
  border: none;
  font-size: 0.85rem;
}

/* media query styling for side bar container */
.media-sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  z-index: 5; /* Needs to sit above the hamburger menu icon */
}

.media-sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  z-index: 5; /* Needs to sit above the hamburger menu icon */
  /* background-color: #0b2239; */
}

.sidenav {
  grid-area: sidenav;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  position: fixed;
  overflow-y: auto;
  transform: translateX(-250px);
  transition: all 0.6s ease-in-out;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08); */
  z-index: 5; /* Needs to sit above the hamburger menu icon */
}

.sidenav__close-icon {
  display: flex;
  position: fixed;
  top: 0.3rem;
  right: 3%;
  cursor: pointer;
}

.menuu-icon svg,
.sidenav__close-icon svg {
  width: 2rem;
  margin-top: 0.8rem;
}

/* menu icon */
.menuu-icon {
  display: flex;
  position: fixed;
  flex-direction: column;
  left: 0.3rem;
  cursor: pointer;
  background-color: #f9f9f9;
}

.user-area {
  display: none;
  margin: 0 0 0;
  padding: 20px 20px 20px 40px;
}

.user-area .user-area-art {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #777;
  color: #fff;
  font-size: 16px;
  padding: 8px 15px;
}

.user-area-art {
  display: none;
}

.user-area p {
  font-size: 0.9rem;
  color: #282828;
  margin: 0;
  padding: 0;
}

.sidenav.active {
  transform: translateX(0);
}

.media-sidenav .switch-wrap,
.sidenav .switch-wrap {
  width: 90%;
  padding: 15px 20px 15px 40px;
}

.sidenav__list {
  padding: 0;
  margin-top: 0px;
  list-style-type: none;
}

.sidenav__list .margin-top {
  flex-grow: 1;
}

.sidenav__list-item {
  cursor: pointer;
}

.sidenav__list-item .list_icon {
  margin-right: 15px;
}

.main {
  grid-area: main;
  background-color: #f9f9f9;
  padding: 25.25px 10px;
}

.main .section-title-ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
}

.main .section-title {
  font-size: 1rem;
  margin: 10px 0 15px;
  padding: 0;
}

.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  /* grid-auto-rows: 94px; */
  grid-gap: 20px;
  margin-bottom: 30px;
}

.settings__title__ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings__title__ctn button {
  background-color: #0064e5;
  color: #fafcff;
  outline: none;
  border: none;
  border-radius: 12px;
  padding: 8px 10px;
  cursor: pointer;
  white-space: nowrap;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-btm {
  padding-bottom: 0 !important;
}

.overview-card-left {
  padding: 15px 20px 0 20px;
  background-color: #d8ffd1;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
}
.overview-card-right {
  padding: 10px 20px;
  background-color: #e6c6ff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
}
.overview-card {
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
  margin-bottom: 25px;
}

.overview-card h2 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.overview-card h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #282828;
  margin: 0;
  padding: 0 0 0;
}

.overview-card p {
  font-size: 0.8rem;
  font-weight: 400;
  color: #5c5c5c;
}

.overview-card table {
  margin-bottom: 0;
}

.overview-card table thead tr th {
  font-size: 0.85rem;
  font-weight: 800;
  border: none;
  padding: 18px 15px;
  box-sizing: content-box;
  white-space: nowrap;
  text-align: center;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}

.overview-card table tbody tr td {
  font-size: 0.75rem;
  font-weight: 600;
  border: none;
  padding: 15px 15px;
  box-sizing: content-box;
  white-space: nowrap;
  text-align: center;
}

.overview-card table tbody tr td .table-product-img {
  width: 50px;
  height: 40px;
  border-radius: 50%;
}

.product-quote .table-product-img {
  width: 40px !important;
  height: 40px !important;
}

.product-quote .table-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overview-card .table-responsive,
.product-quote .table-responsive {
  overflow-x: scroll;
}

.overview-card table tbody tr td p {
  font-size: 0.75rem;
  font-weight: 600;
  margin: 0 !important;
  color: #282828 !important;
}

.overview-card table tbody tr td p a {
  color: #282828;
  transition: all 0.3s ease-out;
}

.overview-card table tbody tr td p a:hover {
  color: #dd4f05;
}

/* .overview-card table tbody tr td .text-warning {
        color: #D8D020!important;
      } */

.overview-card table tbody tr td .text-primary {
  color: #0083d3 !important;
}

.overview-card table tbody tr td .text-success {
  color: #4aae4e !important;
}

.overview-card table tbody tr td .table-order-no {
  font-size: 0.7rem;
  font-weight: 300;
}

.overview-card .table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: rgba(205, 219, 227, 0.01) !important;
}

.overview-card .overview-card-link {
  font-size: 0.8rem;
  color: #0083d3;
  align-self: end;
}

.info-cards {
  column-count: 1;
  column-gap: 20px;
}
.viewall {
  border: none;
  background: none;
  text-align: center;
  color: #0083d3;
  margin-top: 10px;
}
.orderspan {
  display: flex;
  justify-content: space-between;
}
.product-listing {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.product-listing button {
  background: #dd4f05;
  color: white;
  width: 185px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0px;
}
.card {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 35px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  min-height: 200px;
}

.card h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.card p {
  font-size: 0.9rem;
}

/* Force varying colors to simulate dynamic content */
.info-cards .card:first-child {
  background-color: rgba(255, 248, 181, 1);
}

.info-cards .card:nth-child(2) {
  background-color: rgba(255, 221, 204, 1);
}

/* #number {
  width: 16em;
} */

/* Message Center */

.chat-container {
  display: grid;
  grid-template-columns: 1fr;
  /* height: 684px; */
}

/* Chat header */

.chat-wrap .chat-header {
  padding: 18px 30px;
  border-bottom: 1px solid #e4e4e4;
}

.chat-wrap .chat-header .chat-rep-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #e7f3fb;
}

.online-status {
  width: 10px;
  height: 10px;
  background-color: #04a72d;
  border-radius: 50%;
  bottom: 0;
  right: 5px;
}

.chat-wrap .chat-header h2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1.5px;
  padding: 0;
}

.chat-wrap .chat-header p {
  font-size: 0.8rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #5c5c5c;
}

.chat-wrap .chat-header .chat-icon {
  margin: 10px 0 0 25px;
}

.chat-icon:hover,
.send-icon:hover {
  cursor: pointer;
}

/* End of chat header */

.chat-wrap .chat-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 450px 150px;
}

/* Chat messages */

.chat-wrap .chat-area .message-area {
  background-color: #ffffff;
  padding: 25px 30px;
  overflow-y: scroll;
}

.chat-wrap .chat-area .message-area .chat-order-request-msg {
  max-width: 45%;
  background-color: #ffffff !important;
  padding: 30px;
  border-radius: 16px 16px 0px 16px !important;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.1);
  margin-bottom: 40px;
}

.chat-wrap .chat-area .message-area .order-msg img {
  width: 80px;
  border-radius: 2px;
}

.chat-wrap .chat-area .message-area .order-msg h2 {
  margin: 5px 0 5px;
  padding: 0;
  font-size: 1rem;
  color: #dc4d04;
}

.chat-wrap .chat-area .message-area .order-msg p {
  margin: 0 0 0;
  padding: 0;
  font-size: 0.85rem;
  font-weight: 400;
  color: #282828 !important;
}

.chat-wrap .chat-area .message-area .order-msg p span {
  color: #5c5c5c;
  font-size: 0.7rem;
}

.chat-wrap .chat-area .message-area .chat-msg {
  max-width: 90% !important;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  font-size: 0.85rem;
  margin-bottom: 40px;
  clear: both;
}

.chat-wrap .chat-area .message-area .chat-msg p {
  font-size: 0.85rem;
  color: #282828 !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.chat-wrap .chat-area .message-area .chat-order-request-msg .chat-timestamp {
  font-size: 0.7rem !important;
  margin: 10px 0 0;
  font-weight: 400;
  color: rgb(182, 182, 182) !important;
}

.chat-wrap .chat-area .message-area .chat-order-request-msg .order_msg-btn {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: rgba(11, 34, 57, 0.81);
  border-radius: 50px;
  font-size: 0.8rem;
  margin: 15px 10px 5px 0;
  padding: 6px 15px !important;
}

.chat-wrap
  .chat-area
  .message-area
  .chat-order-request-msg
  .order_msg-btn:hover {
  background-color: rgba(220, 77, 4, 0.1);
  border-color: rgba(220, 77, 4, 0.3);
}

.chat-wrap .chat-area .message-area .chat-msg .chat-timestamp {
  font-size: 0.7rem;
  margin: 10px 0 0;
  font-weight: 400;
  color: rgb(182, 182, 182) !important;
}

.chat-wrap .chat-area .message-area .sender {
  float: right;
  background-color: #78bef71a;
  border-radius: 16px 16px 0px 16px !important;
}

.chat-wrap .chat-area .message-area .receiver {
  float: left;
  background-color: #dc4d041a;
  border-radius: 16px 16px 16px 0px !important;
}

/* End of chat messages */

/* Chat message input */

.chat-wrap .chat-area .message-input {
  width: 100%;
  padding: 30px 30px 0;
}

.chat-wrap .chat-area .message-input .msg-center-btn {
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: rgba(11, 34, 57, 0.81);
  border-radius: 4px !important;
  font-size: 0.8rem;
  margin-bottom: 5px;
  padding: 6px 15px !important;
}

.chat-wrap .chat-area .message-input .msg-center-btn:hover {
  background-color: rgba(220, 77, 4, 0.1);
  border-color: rgba(220, 77, 4, 0.3);
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area {
  height: 50px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  align-items: center;
}

.chat-wrap .chat-area .message-input .chat-form {
  margin: 10px 0;
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area:focus-within {
  border-color: #bbdef6;
}

.chat-wrap .chat-area .message-input .chat-form .chat-input-area .send-icon {
  margin-right: 25px;
}

.chat-wrap .chat-area .message-input .chat-form textarea {
  border: none;
  font-size: 0.9rem;
  padding: 12px 15px;
  resize: none;
  align-items: center;
  height: 50px;
}

/* end of chat message input */

/* Paginantion */

.pagination .page-item {
  margin: 0 10px;
}

.pagination .page-item .page-link {
  border-radius: 4px !important;
  border: none !important;
  background-color: transparent;
  font-size: 0.9rem;
  font-weight: 500;
  color: #7f8d96;
}

.pagination .page-item .page-link:hover {
  color: #0083d3;
}

.pagination .page-item.active .page-link {
  background-color: #0083d3;
  color: #ffffff;
}

/* End of Pagination */

.footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  color: #5c5c5c;
  padding: 10px 55px;
  font-size: 0.85rem;
}

.seller-setting-profile {
  color: #282828;
}

.seller-setting-profile .seller-setting-header-ctn {
  display: flex;
  margin-bottom: 10px;
}

.seller-setting-header-ctn h4 {
  margin-right: 15px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: #5c5c5c;
}

.seller-setting-header-ctn .active {
  border-bottom: 2px solid #0b2239;
  padding-bottom: 6px;
}

.seller-setting-profile a {
  color: #dc4d04;
  text-decoration: underline;
}

.seller-profile-pic img {
  width: 100%;
  height: auto;
}
.seller-setting-form {
  margin: 30px 0;
  width: 100%;
}

.deactivated {
  display: none;
}

.bank-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.09);
  padding: 15px 10px;
  margin: 15px;
}

.bank-content .bank-wrapper {
  display: flex;
  margin: 5px 0;
}

.bank-wrapper label {
  font-size: 14px;
  font-weight: 700;
  width: 130px;
}

.bank-wrapper div {
  font-size: 14px;
}

.bank-svg-ctn svg {
  width: 18px;
  cursor: pointer;
}

.bank-svg-ctn .delete-bank svg {
  color: red;
  margin-left: 8px;
}

.seller-setting-form .form-group {
  margin: 20px 0;
}
.seller-setting-form input {
  padding: 0px 10px;
}
.seller-setting-form textarea {
  padding: 10px 15px;
  width: 100%;
}
.password input {
  padding: 0px 10px;
  width: 182px;
}
.savechanges {
  background-color: #fff;
  color: #dd4f05 !important;
  border: 1px solid !important;
  width: 180px;
}

.changepassword {
  margin-right: 20px;
  width: 180px !important;
  background: #dd4f05 !important;
  border: none;
}
.buyerchangepassword {
  min-width: 180px !important;
  background: #0064e5 !important;
  border: none;
  font-size: 16px;
}

.buyersavechanges {
  margin-right: 20px !important;
  font-size: 16px;
  border: 1px solid !important;
  color: #0064e5 !important;
  background-color: #fff !important;
  min-width: 180px;
}

.seller-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.seller-seting-submit,
.seller-seting-joindate {
  width: 50%;
}

.address {
  height: 54px;
}

.seller-seting-joindate p {
  font-weight: 700;
  color: #1b2631;
  font-size: 14px;
  line-height: 18px;
  margin: 20px 0px 0px 50px;
  min-width: fit-content;
}
.seller-seting-joindate {
  display: flex;
}
.Subscription-info {
  border: 1px solid rgba(11, 34, 57, 0.12);
  border-radius: 4px;
  padding: 10px;
  width: 188px;
}
.Subscription-info button {
  border: none;
  color: #0064e5;
  background: none !important;
}
.chat-main {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
}

.web-version {
  display: grid;
}

.mobile-version {
  display: none;
}
.userlist {
  width: 100%;
}

.overview-card-userlist {
  padding: 30px 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
}
.message-center-header {
  display: flex;
  justify-content: space-between;
}
.horizontal-line {
  border-bottom: 0.5px solid #e4e4e4;

  margin: 0 -1.3em;
}
.message-header-status {
  margin-left: 10px;
}
.message-header-profilepic {
  display: flex;
}

.chat-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 450px 150px;
}

.message-dialogue {
  padding-top: 20px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 1px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(220, 218, 218);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgb(183, 171, 171);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cb9e9e;
}

.chat-msg {
  max-width: 90% !important;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  font-size: 0.85rem;
  margin-bottom: 10px;
  clear: both;
}

.receiver {
  float: left;
  background-color: rgba(220, 77, 4, 0.1);
  border-radius: 16px 16px 16px 0px !important;
}

.sender {
  float: right;
  background-color: rgba(120, 190, 247, 0.1);
  border-radius: 16px 16px 0px 16px !important;
}

.chat-msg p {
  font-size: 0.85rem;
  color: #282828 !important;
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

.chat-msg .chat-stamp {
  float: right;
  font-size: 0.7rem;
  margin: 0px 0 0;
  font-weight: 400;
  color: rgb(182, 182, 182) !important;
}

.MessageCenterform-chatmessage {
  width: 100%;
  border: 1px solid #d9d9d9;
  filter: drop-shadow(0px 4px 21px rgba(11, 34, 57, 0.13));
  border-radius: 8px;
  height: 52px;
}

.MessageCenterform-chatmessage button {
  background-color: #f9f9f9 !important;
  border: none !important;
  color: #282828 !important;
}

.message-file-picker {
  display: none;
}

.message-file-picker span {
  margin-right: 20px;
}
.MessageCenterform-chatmessage {
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.MessageCenterform-chatmessage textarea {
  width: 100%;
  border: none;
  background: none;
  height: 100%;
}
.messagecenter-search {
  width: 90%;
  margin: 10px auto 20px auto;
  padding: 0 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.08);
}
.messagecenter-search input {
  border: none;
  font-size: 0.85rem;
}
.header__message button,
.Seller-order-info-header button {
  background: none;
  border: none;
}

.react-confirm-alert-button-group button {
  background-color: #0064e5 !important;
}

.status-order {
  width: max-content !important;
  border-radius: 10px !important;
  font-size: 12px !important;
  padding: 0.2rem 0.75rem;
}

.status-order:focus {
  border: 1px solid #f24e1e;
}

.div-form-control {
  width: 100%;
  height: 43px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: rgba(40, 40, 40, 0.1);
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.modal-footer .msg-btn {
  background-color: #4750f4 !important;
  color: #fff !important;
  border-radius: 4px;
  padding: 10px 30px !important;
  text-decoration: none !important;
}

.payment-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 20px 10px;
  box-sizing: content-box;
}

.payment-modal h6 {
  white-space: pre-wrap;
}

.payment-modal .title {
  color: #dc4d04;
}

.nigeria-order-ctn {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.nigeria-order-ctn h1 {
  font-size: 16px;
  font-weight: 800;
}

.nigeria-order-ctn select {
  height: 30px !important;
  width: 80px;
  margin-left: 10px;
  border: 1px solid #5c5c5c;
  outline: none;
  border-radius: 4px;
}

.nigeria-order-ctn div {
  margin-bottom: 30px;
}

.nigeria-order-ctn ul {
  list-style: none;
  padding-left: 0;
  text-align: left;
}

.nigeria-order-ctn ul li span {
  font-weight: 900;
}

.nigeria-order-ctn ul li a {
  text-decoration: underline !important;
  font-weight: 800;
}

.nigeria-order-ctn button {
  border-radius: 6px;
  border: none;
  background-color: #f9744b;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 30px;
}

.payment-modal .notice,
.overview-card table tbody tr td .rfq-modaal p {
  margin-top: 30px;
  margin-bottom: 40px !important;
  white-space: pre-wrap;
  text-align: left;
}

.payment-modal .notice .danger,
.overview-card table tbody tr td .rfq-modaal p .danger {
  font-weight: 800 !important;
}

.quote-titlee {
  font-size: 16px;
}

.userlist-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 0.7px solid #ededed;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.userlist-container:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.userlist-textImage {
  display: flex;
}
.userlist-info {
  margin-left: 10px;
  line-height: 2;
}
.userlist-info p {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
}
.userlist-info h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #303030;
  margin: 0 !important;
}
.userlist-time p {
  font-weight: 300;
  font-size: 10px;
  line-height: 13px;
  margin: 0px !important;
  letter-spacing: 0.01em;
  color: rgba(40, 40, 40, 0.71);
}
.userlist-time span {
  margin-left: 20px;
  padding-top: -20px;
}
.doublecheck {
  color: #33d139;
}
.not-delivered {
  color: #f24e1e;
}
.overview-card-buyers-info {
  padding: 30px 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(11, 34, 57, 0.12);
}
.buyer-info-pic {
  border-bottom: 1px solid #dddddd;
  text-align: center;
}

.buyer-info-pic h4 {
  font-size: 16px;
}
.buyer-info-pic p {
  font-size: 14px;
}

.buyer-info {
  display: flex;
  margin: 10px 0;
}
.buyer-info span {
  margin-right: 10px;
  font-size: 12px;
}
.buyer-info p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  word-wrap: break-word;
}
.buyer-info h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
}
.buyer-info button {
  padding: 0 !important;
  font-size: 12px;
}
.buyer-info-details {
  padding: 20px;
}
.buyer-info-details h4 {
  margin: 30px 0;
}
.messagebuyer {
  margin-top: 30px;
}
.messagebuyer button {
  border: none;
  background: none;
  color: #0064e5;
  text-decoration: underline;
}
.messageseller button {
  border: none;
  background: none;
  color: #dd4f05;
  text-decoration: underline;
}
.messagebuyer span {
  color: #0064e5;
}
.product-info {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: rgba(205, 219, 227, 0.1);
  margin: 20px 0;
}

.product-infoo {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #ffffff;
  margin: 20px 0;
}

.product-info-title,
.product-info-description {
  width: 50%;
}

.product-info-title {
  font-weight: 900 !important;
}

.product-info p,
.product-requirement p {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #282828;
  border: none;
  white-space: pre-wrap;
}

.product-infoo p {
  font-size: 12px;
}

.product-requirement {
  padding: 20px 30px;
  background: rgba(205, 219, 227, 0.1);
  margin: 30px 0px;
}
.respond-btn {
  background: #dd4f05 !important;
  border-radius: 4px !important;
  border: none !important;
  width: 164px !important;
  font-size: 16px !important;
}

.quote {
  margin: 30px 0;
}
.product-quote {
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
}

.product-quote h5,
.buyer-info-details h4 {
  font-size: 15px;
}

.modal {
  left: 5%;
  border-radius: 12px;
  top: -2%;
}
.modal-body {
  padding: 0 !important;
}
.modal-footer {
  display: block;
  padding: 12px 0;
  border: none;
}
.modal-footer button {
  width: 40%;
  margin: 0 !important;
}
.quote-modal {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  min-width: 100% !important;
}
.rfq-details,
.supplier-response {
  padding: 10px;
  width: 100%;
}

.supplier-response {
  background: #fafcff;
}
.supplier-product-info {
  padding: 5px;
}
.supplier-form-group {
  margin: 40px 0;
}
.supplier-form-group label {
  margin-bottom: 15px;
}
.supplier-form-group input,
.supplier-form-group textarea {
  width: 100%;
}
.supplier-product-requirement {
  margin: 30px 0px;
  padding: 15px 10px;
  width: 100%;
}
.sellerinfomain {
  padding: 0;
}
.Seller-order-info {
  padding: 25.5px 35px;
}

.Seller-order-info-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 0 20px;
}
.sellerInfoOrder {
  margin: 0 !important;
}
.Seller-order-complete {
  background: #f5f5f5;
  padding: 30px 0px;
}
.order-complete-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding-left: 10px;
}
.response-title {
  display: flex;
  align-items: center;
  position: relative;
}

.review-button-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-button-ctn button {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: medium;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  font-weight: 700;
}

.review-button-ctn button:hover {
  color: #0083d3;
}

.enter-review-ctn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.enter-review-ctn div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.enter-review-ctn label {
  font-size: 16px;
  color: #303030;
  text-transform: capitalize;
}

.enter-review-ctn select,
.enter-review-ctn input {
  height: 56px !important;
  border-radius: 12px;
  padding: 0 4px;
  outline: none;
  border: 1px solid #8a8383 !important;
}

.enter-review-ctn input:focus {
  border-color: #dd4f05 !important;
}

.enter-review-ctn .review-file input {
  border: none !important;
}

.opacity p {
  opacity: 0.3;
}

.response-title i {
  font-size: 14px;
  margin-left: 2px;
}

.response-title .current {
  position: absolute;
  left: 7px;
  color: #4aae4e;
}

.response-title .next {
  position: absolute;
  left: 7px;
  color: #ffc124;
}

.response-title .wait {
  position: absolute;
  left: 7px;
  color: #7f8d96;
}

.response-title p {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin: 0 !important;
  color: #282828;
  margin-left: 30px !important;
}
.response-border {
  border-left: 1.5px dashed #282828;
  margin: -8px 15px;
}
.response-content {
  display: flex;
}
.response-content p {
  padding: 10px 0;
  margin: 0 !important;
}
.response-content {
  width: 265px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(40, 40, 40, 0.85);
}

#start-order-button {
  margin: 10px 0 0px 10px !important;
  padding: 0.5rem 0 !important;
  width: 150px;
  font-size: 12px;
}

#message-button {
  width: 150px;
  font-size: 12px;
  margin: 10px 0 0px 10px !important;
  padding: 0.5rem 0 !important;
}

.Seller-order-info-header .fa-chevron-left {
  color: #1b2631;
}

.response-content label {
  width: max-content;
  cursor: pointer;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  padding-right: 15px !important;
}

.response-content .upload-di {
  border: 1px solid #dddddd;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  margin-right: 0px;
  margin-bottom: 15px;
  color: #0083d3;
  text-decoration: underline;
}

.response-content .upload-ii {
  margin-right: 5px;
}

.response-content .inputtt {
  display: none;
  visibility: hidden !important;
  height: 0px;
  width: 0px;
}

.respose-content-text {
  padding-right: 15px;
}

.respose-content-text p {
  font-size: 14px;
}

.last {
  border: none;
  margin: -13px 15px;
}
.product-info-title-user {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.product-info-title-user button {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  border: none;
  background: none;
  text-decoration-line: underline;
  color: #0064e5 !important;
}
.Seller-order-info-completed {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background: #ffffff !important;
  border-radius: 16px;
  padding: 7px 20px;
}
.product-info-name {
  margin-right: 30px;
}
.product-description-button-container {
  display: flex;
  justify-content: space-between;
}
.product-description-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.productdescriptionoverview {
  border: 1px solid red;
  width: 100%;
}
.product-description-container {
  display: flex;
  justify-content: space-evenly;
}
.Product-description-information-listitem {
  display: flex;
  justify-content: space-between;
}

.Product-description-information-container {
  border: 1px solid #dddddd;
  border-radius: 10px;
}
.Product-description-information-box {
  width: 33%;
}
.Product-description-information-box-title h4 {
  font-weight: 800 !important;
  line-height: 19px;
  color: #282828;
}
.product-desc-details {
  width: 50%;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #000000;
}

.Product-description-specification,
.Product-description-information {
  padding: 5px;
}

.Product-description-subimage {
  display: flex;
  margin: 5px 0 10px;
}

.Product-description-mainimage {
  height: 300px !important;
  width: 300px;
}

.Product-description-mainimage img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.Product-description-thumbimage {
  width: 80px !important;
  height: 80px !important;
  margin-right: 5px;
}

.Product-description-thumbimage:active {
  border: 2px solid #dc4d04 !important;
}

.Product-description-thumbimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-information-description {
  font-style: normal;

  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  padding: 20px;
  color: #1b2631;
  text-align: justify;
}
.product-information-description-text {
  margin: 20px 0;
}
.product-information-description h4,
.Product-description-information-container h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 42px;
  color: #282828;
  margin: 0 !important;
}
/* .Product-description-image{
    width: 20%;
  } */
.product-description-line {
  border: 0.5px solid #0064e5;
  width: 155px;
}
.new-product-container {
  display: flex;
}
.new-product {
  margin-bottom: 20px;
}
.product-row {
  display: flex;
  justify-content: space-between;
}
.product-info-description {
  width: 100%;
  margin: 10px 0;
}
.product-info-description-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #1b2631;
}

.product-info-description textarea {
  border: none !important;
}
.image-upload {
  border: 1px solid grey;
  border-style: dashed;
}

.image-upload label {
  fcursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
}

.image-upload input {
  visibility: hidden !important;
}

.image-upload button {
  padding: 5px 50px !important;
}
.categories {
  margin: 0px 20px 30px 20px;
  width: 35%;
}

.categories h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #282828;
  margin-bottom: 10px;
}
.categorieslist-container {
  margin: 0 !important;
}
.categorieslist {
  display: flex;
  align-items: center;
  margin: 10px 0;
  justify-content: space-evenly !important;
  transition: all 0.3s ease-in-out;
}

.categorieslist:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.categoriesdescription {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  cursor: pointer;
}
.categoriesdescription img {
  margin-right: 20px;
  width: 12px;
  height: 12px;
  object-fit: cover;
}

.showw {
  display: block;
  transition: all 0.4s ease-in-out;
}

.hidee {
  display: none;
  transition: all 0.4s ease-in-out;
}

.layer-one-margin {
  margin-left: 10px;
}
.layer-two-margin {
  margin-left: 20px;
}
.layer-three-margin {
  margin-left: 30px;
}
.layer-four-margin {
  margin-left: 40px;
}
.layer-five-margin {
  margin-left: 50px;
}
.layer-six-margin {
  margin-left: 60px;
}
.layer-seven-margin {
  margin-left: 70px;
}

.product-row input::placeholder,
.product-row option {
  font-size: 10px;
}
.product-row-info {
  margin: 10px 0;
}
.product-row-info label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #282828;
}
.productname {
  width: 25%;
}
.productorigin,
.productprice {
  width: 35%;
}
.productleadtimemax,
.productleadtimemin {
  width: 28%;
}
.productcapacity {
  width: 40%;
}
.new-product-container-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #282828;
}
.productpricediv,
.supplycapacitydiv,
.minimumorderdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  height: 45px;
}

.supplycapacitydiv select,
.minimumorderdiv select {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  margin: 0 !important;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 15px;
  color: #000000 !important;
  padding: 10px;
}

.productpricediv p {
  margin: 0 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px;
  color: #000000 !important;
  padding: 10px;
}

.minimumorderdiv input {
  height: 30px;
  border: none;
  border-right: 1px solid #dddddd;
}

.supplycapacitydiv input {
  width: 30%;
  height: 30px;
  border: none;
  border-right: 1px solid #dddddd;
}
.supplycapacitydiv input {
  width: 70%;
  height: 30px;
  border: none;
  border-right: 1px solid #dddddd;
}
.specificationcontainer {
  display: flex;
}
.productspecificationcontainer,
.minimumordercontainer {
  width: 48%;
}

.productspecification-value input {
  border: none !important;
  width: 70% !important;
}
.productspecification-value {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 4px;
  align-items: center;
}

.productprice-button button,
.productspecification-value button {
  border: none;
  background: none;
}

.productspecification-value i {
  font-size: 8px;
}

.categories-footer-button {
  width: 180px;
  border: none !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.categories-footer-button button {
  border: none;
  width: 100%;
  border: 1px solid green;
  color: #fff;
  background: #dd4f05 !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 10px 15px;
}
.product-row-info p,
.featured-image p {
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  color: #5c5c5c;
}

.featured-image p a {
  color: #dc4d04;
}

.productspecification {
  display: flex;
  justify-content: space-between;
}
.product-info-description {
  /* border: 1px solid rgba(11, 34, 57, 0.21); */
  border-radius: 4px;
  padding: 10px;
}

.pro-des {
  border: 1px solid rgba(11, 34, 57, 0.21);
}
.categories-button {
  position: absolute;
  margin: auto;
}
.categories-footer {
  display: flex;
}
.image-upload {
  text-align: center;
}
.categorieslist p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;
  margin: 0 !important;
}
.sub-categories {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #282828;
  margin-top: 20px;
  margin-bottom: 8px !important;
}

.buyerviewquote {
  margin-left: 20px;
  margin-top: 10px;
  text-decoration: underline;
  font-size: 14px;
}

.product-quote td {
  padding: 20px 10px;
}
.response-content-date {
  display: flex;
  flex-direction: row-reverse;
  font-size: 10px !important;
}
.buyer-price {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  margin: 20px 20px 20px 20px;
}
.buyersformgroup {
  margin: 20px 20px 120px 20px;
  background: #fff;
  padding: 30px 20px;
}
.buyer-price form {
  margin: 0 !important;
  width: 80%;
}
.buyer-price p,
.buyer-quotedetails p {
  font-size: 16px !important;
  margin: 0 !important;
}
.buyer-price input {
  border: none;
  width: 80% !important;
}
.buyerrfqdetails {
  width: 90%;
  padding: 5px;
}
.buyer-quotedetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  margin: 20px 0;
  background-color: rgba(205, 219, 227, 0.1);
}
.buyer-quotedetails input {
  border: none;
  background-color: rgba(205, 219, 227, 0.1);
}
.quote-product-requirement {
  background: rgba(205, 219, 227, 0.1);
  padding: 30px 20px;
}
.quote-product-requirement p,
.buyersformgroup p {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #282828;
}

.buyer-modal-footer {
  display: flex;
  justify-content: space-evenly;
}
.buyer-modal-footer button {
  padding: 10px 0px;
}

.image-container {
  justify-content: center;
}

.send-image {
  display: flex;
  width: 80px;
  height: 75px;
  margin-right: 5px;
  position: relative;
}

.send-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.send-image .bin-icon {
  position: absolute;
  top: 75%;
  right: 0%;
  color: red;
  cursor: pointer;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.empty-state h3 {
  font-size: 1.5rem;
  color: #282828;
  margin-bottom: 15px;
  font-weight: 500 !important;
}

.empty-state p {
  font-size: 14px;
  color: #282828;
  font-weight: 500 !important;
}

.empty-state a {
  color: #dc4d04;
}

/* start dropdown for rfqs */

.sidebar-item {
  display: block;
}

.sidebar-item .toggle-btn {
  transition: all 0.4s ease-in-out;
}

.sidebar-item.open .toggle-btn {
  transform: rotate(360deg);
}

.sidebar-item .sidebar-content {
  padding-top: 0.25em;
  padding-left: 2rem;
  height: 0;
  transform: translateY(-100%) scaleY(0);
  transition: all 0.4s ease-in-out;
}

.sidebar-item.open .sidebar-content {
  height: 100px;
  padding-bottom: 20px;
  transform: translateY(0%) scaleY(1);
}
/* end dropdown for rfqs */

.add-radius {
  border: 1px solid #0064e5 !important;
}

.first-letter {
  background-color: #1b2631;
  color: #f9f9f9;
  border-radius: 50%;
  padding: 12px 20px;
}

.second-letter {
  background-color: #1b2631;
  color: #f9f9f9;
  border-radius: 50%;
  font-size: 20px;
  padding: 14px 25px !important;
}

.order-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 20px;
}

.modal-body .order-msg h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #4aae4e;
}

.modal-body .order-msg p {
  font-size: 16px;
  font-weight: 700;
}

.rfq-title-ctn {
  font-size: 14px !important;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .grid-container {
    grid-template-columns: 240px 1fr;
    grid-template-rows: 80px 1fr;
    grid-template-areas:
      "sidenav header"
      "sidenav main"
      "sidenav footer";
  }

  .header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 55px;
  }

  .user-area {
    display: block;
  }

  .user-area-art {
    display: block;
  }

  .header__search {
    margin-left: 0;
  }

  .header__search {
    width: 350px;
  }

  .custom__search {
    width: 350px;
  }

  .sidenav {
    /* position: relative; */
    transform: translateX(0);
  }

  .sidenav__close-icon {
    display: none;
  }

  .sidenav .switch-wrap {
    width: 72%;
    padding: 15px 20px 15px 40px;
  }

  .main {
    padding: 25.25px 55px;
  }

  .main .section-title-ctn {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .message-file-picker {
    display: block;
  }
  .chat-wrap .chat-area .message-area .chat-msg,
  .chat-msg {
    max-width: 45% !important;
  }

  .quote-titlee {
    font-size: 20px;
  }

  .rfq-details,
  .supplier-response {
    padding: 20px;
    width: 100%;
  }

  .product-info {
    margin: 20px;
  }
  .supplier-product-requirement {
    margin: 20px;
  }
  .product-infoo {
    margin: 20px;
  }
  #start-order-button {
    margin: 10px 0 0px 10px !important;
    width: 160px;
    padding: 0.5rem;
    font-size: 14px;
  }

  #message-button {
    width: 160px;
    margin: 10px 0 0px 10px !important;
    padding: 0.5rem;
    font-size: 14px;
  }
  .payment-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .response-content .upload-di {
    align-items: center;
    text-align: center;
    padding: 5px 20px;
    margin-right: 5px;
    margin-bottom: 0;
  }
  .Seller-order-info-header {
    margin: 20px 0;
  }

  .Seller-order-complete {
    background: #f5f5f5;
    padding: 30px 20px;
  }
  .order-complete-title {
    font-size: 24px;
    line-height: 30px;
  }

  .response-title p {
    font-size: 18px;
    line-height: 23px;
  }
  .overview-card-buyers-info {
    padding: 30px;
  }
  .product-requirement p {
    font-size: 14px;
  }

  .product-infoo p {
    font-size: 14px;
  }

  .seller-setting-form {
    width: 80%;
  }
  .buyersavechanges {
    margin-bottom: 5px;
  }

  .status-order {
    font-size: 14px !important;
  }
  .buyer-info span {
    margin-right: 40px;
    font-size: 14px;
  }
  .buyer-info p {
    font-size: 13px;
    line-height: 150%;
  }
  .buyer-info button {
    font-size: 14px;
  }
  .buyer-info-pic h4 {
    font-size: 20px;
  }
  .buyer-info-pic p {
    font-size: 18px;
  }
  .product-quote h5,
  .buyer-info-details h4 {
    font-size: 18px;
  }
  .rfq-title-ctn {
    font-size: 16px !important;
  }
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .info-cards {
    column-count: 2;
  }
}
@media only screen and (max-width: 1200px) {
  .seller-profile-card {
    display: grid;
    grid-template-columns: 1fr;
  }
  .seller-setting-formgroup {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px;
  }
  .seller-footer {
    display: grid;
    grid-gap: 20px;
  }
  .seller-seting-joindate p {
    margin: 0 10px;
  }
  .seller-seting-submit button {
    margin: 10px;
  }

  .sellerInfoOrder {
    margin: 0 !important;
    display: grid;
    grid-template-columns: 1fr;
  }
  .response-content p {
    font-size: 12px;
    word-wrap: break-word;
  }

  .product-description-container {
    display: grid !important;
    grid-template-columns: 1fr;
  }
  .Product-description-information-box {
    width: 100%;
    margin: 20px 0;
  }

  .Product-description-thumbimage img {
    width: 80px;
  }
  .modal {
    left: 5%;
    border-radius: 12px;

    padding: 20px;
    margin-top: -10px;
  }
  .new-product-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .new-product {
    width: 100%;
    margin: 0px;
  }
  .categories-footer {
    display: flex;
    flex-direction: column;
  }
  .categories {
    width: 70%;
    margin: 0px;
  }
  .categories-footer-button button {
    width: 100%;
  }
  .categories-footer-button {
    margin-left: 0px;
    margin-top: 20px;
  }

  @media (max-width: 990px) {
    .quote-modal {
      flex-direction: column;
    }

    .chat-main {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
    }

    .web-version {
      display: none;
    }

    .mobile-version {
      display: grid;
    }
    .categories {
      width: 100%;
    }

    .overview-card table thead tr th {
      font-size: 0.75rem;
    }

    .overview-card table tbody tr td {
      font-size: 0.7rem;
    }
    .order-msg {
      padding: 20px;
    }

    .order-msg p {
      font-size: 12px;
    }
  }
}
