.product-description-content p {
  font-size: 16px;
  font-weight: 500;
  color: #1b2631;
  padding: 50px 0 0;
}

.not-active {
  display: none;
}

@media (max-width: 768px) {
  .product-description-content p {
    font-size: 12px;
  }
}
