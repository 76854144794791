.payment-modal-content {
  text-align: center;
  padding: 30px 0;
}

.payment-modal-content img {
  width: 50px;
  height: auto;
  margin-bottom: 10px;
}

.amount {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 600;
}

.phone-input {
  margin-bottom: 20px;
}

.note-input {
  margin-bottom: 10px;
}

.momo-note {
  margin-bottom: 20px;
  font-size: 14px;
  color: #888;
}

.payment-modal-content .ant-spin-dot-item {
  background-color: #fff;
}
