#product-listing {
  padding: 40px 0;
}

.filter-head {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.filter-head h2 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  padding: 0;
}

.filter-head p {
  font-size: 0.85rem;
  margin: 0;
  padding: 0;
}

.filter-head p a {
  color: #282828;
}

.filter-head .scroll-icon {
  cursor: pointer;
}

.pagination__ctn {
  max-width: 40%;
}

@media (min-width: 1200px) {
  .filter-head {
    flex-direction: row;
    margin-bottom: 20px;
  }
  .filter-head h2 {
    margin: 0;
  }
}

@media (max-width: 700px) {
  .pagination__ctn {
    max-width: 80%;
  }
}
