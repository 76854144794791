.auth {
  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
}

.auth-lhs {
  background-image: url(../../assets/img/auth-bg.jpg);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.auth .auth-lhs {
  background-color: #fdf2e7;
  padding: 20px;
}

.auth .auth-rhs {
  background-color: #ffffff;
  padding: 20px 50px;
}

.auth-rhs-header {
  padding: 10px 0;
}

.auth-rhs-header p {
  font-size: 0.9rem;
  margin: 0;
  align-self: center;
}

.auth-rhs-header a {
  color: #282828;
}

.auth-rhs-header .user-link {
  color: #dd4f05;
  font-weight: 600;
}

.auth-rhs-header a:hover {
  color: #dd4f05;
}

.auth-rhs-content {
  width: 75%;
  height: 90%;
  margin: 0 auto;
}

.rhs-inner {
  width: 100%;
}

.auth-rhs-content h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0 0 10px;
}
.auth-rhs-content h2 span {
  font-weight: 400;
}

.auth-rhs-content h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 20px 0;
}

.auth-rhs-content .phone-v-inst {
  font-size: 0.9rem;
  color: #282828 !important;
}

.auth-rhs-content .phone-v-inst span {
  font-weight: 600;
}

.auth-rhs-content p {
  font-size: 0.9rem;
  color: #5c5c5c;
  margin: 0;
  padding: 0 0 30px;
}

.auth-account-wrap {
  text-decoration: none;
}

.auth-account {
  width: 100%;
  padding: 25px;
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.auth-account h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
  padding: 0 0 5px;
}

.auth-account p {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

.auth-cta-icon {
  visibility: hidden;
  color: #dd4f05;
}

.auth-user-icon {
  color: #dd4f05;
  margin-right: 25px;
  font-size: 25px;
}

.auth-account:hover {
  border: 1px solid #dd4f05;
  background-color: #fdf2e7;
}

.auth-account:hover .auth-cta-icon {
  visibility: visible;
}

.auth-account:hover .auth-user-icon {
  color: #dd4f05;
}

.auth-form {
  width: 90%;
  margin-top: 0px;
}

.auth-form label {
  font-size: 0.9rem;
  font-weight: 600;
}

.auth-form .form-check-input:checked {
  background-color: #dd4f05;
  border-color: #dd4f05;
}

.auth-form input {
  font-size: 0.9rem;
}

/* .auth-form .custom-checkbox {
    height: 14.4px!important;
    width: 14.4px!important;
    margin-top: 6px;
  } */

.auth-form select {
  font-size: 0.9rem;
}

.auth-form textarea {
  font-size: 0.9rem;
}

.auth-form input:focus {
  border-color: #dd4f05;
}

.auth-form select:focus {
  border-color: #dd4f05;
}

.auth-form textarea:focus {
  border-color: #dd4f05;
}

.passwordToggle:focus-within {
  border-color: #dd4f05;
}

.PhoneInput:focus-within {
  border-color: #dd4f05;
}

.auth-form button {
  margin-top: 10px;
  width: 100%;
  background-color: #dd4f05;
  border-color: #dd4f05;
  font-size: 0.9rem;
  font-weight: 700;
  padding: 10px 0px;
}

.auth-form .user-link-doc {
  color: #282828;
  text-decoration: underline;
}

.auth-form .forgot-pwd {
  font-size: 0.9rem;
  font-weight: 600;
}

.auth-form p {
  font-size: 0.9rem;
  margin: 0;
  padding: 20px 0 0;
  color: #282828;
  font-weight: normal;
}

.auth-form a {
  color: #dd4f05;
  font-weight: 600;
}

.auth-lhs-header {
  padding: 10px 50px;
}

.auth .site-logo {
  width: 100px;
}

.auth-lhs-content {
  width: 75%;
  height: 90%;
  margin: 0 auto;
}

.auth-lhs-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  /* line-height: 45px; */
  margin: 0 0 30px;
  padding: 0;
}

.auth-lhs-content p {
  margin: 0 0 25px;
  padding: 0;
  font-size: 1rem;
}

.mobile-only {
  visibility: hidden;
}

.PhoneInput {
  height: 45px;
}

.PhoneInputInput {
  border: 0;
  padding: 0;
  margin: 0;
  flex: 1 1;
  min-width: 0;
  overflow: visible;
}

.passwordToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.passwordToggle > input {
  width: 80%;
  border: none;
}

.error-message,
.errors {
  color: red !important;
  font-size: 12px !important;
  padding-top: 2px !important;
}

.verify-email-ctn {
  display: flex;
  height: 100vh;
  width: 100%;
}

.verify-email {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

@media (max-width: 768px) {
  .mobile-only {
    visibility: visible;
  }

  .auth .site-logo {
    width: 80px;
  }

  .auth {
    grid-template-columns: 100% 100%;
  }

  .auth-rhs-content {
    width: 100%;
  }

  .auth-lhs {
    display: none;
  }

  .auth .auth-rhs {
    padding: 20px;
  }

  .auth-account {
    padding: 15px;
  }

  .auth-account h3 {
    font-size: 1rem;
  }

  .auth-account p {
    font-size: 0.8rem;
  }

  .auth-form {
    width: 100%;
  }
}
