.mp-wrap .main-product-pic {
  width: 90%;
  height: 350px;
  background-color: #f9f9f9;
  margin-bottom: 6px;
}
.mp-wrap .main-product-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mp-wrap .product-thumbnail {
  width: 90%;
}

.mp-wrap .product-thumbnail .pt-box {
  width: 80px;
  height: 75px;
  background-color: #f9f9f9;
  margin-right: 4px;
  margin-bottom: 4px;
}

.mp-wrap .product-thumbnail .pt-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mp-wrap .product-thumbnail .pt-box:active {
  border: 2px solid #dc4d04;
}

.product-detail-ctn .product-name {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
  padding: 0 0 30px;
}

.product-price {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
  padding: 0 0 10px;
}

.product-price .p-currency {
  font-size: 13px;
  color: #5c5c5c;
  font-weight: 300;
}

.product-detail-ctn .product-price .p-unit {
  font-size: 13px;
  font-weight: 600;
}

.capacity-ctn {
  padding: 0 0 10px;
}

.radius {
  border-right: 1px solid #b1afaf;
  margin-right: 5px;
  padding: 0 5px 0 0;
}

.capacity {
  padding: 0 !important;
}

.product-specification-ctn {
  margin: 40px 0 0;
}

.product-specification-ctn .product-specification-title {
  font-size: 15px;
  font-weight: 700;
  margin: 0 0 20px;
}

.product-specification-ctn .product-specification-content {
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 30px 10px 10px;
  margin: 10px 0;
}

.product-specification-ctn
  .product-specification-content
  .product-specification-list {
  width: 100%;
  display: flex;
}

.product-specification-ctn
  .product-specification-content
  .product-specification-list
  p {
  margin: 0 0 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.product-specification-ctn
  .product-specification-content
  .product-specification-type {
  color: #5c5c5c;
  width: 30%;
}

.product-specification-ctn
  .product-specification-content
  .product-specification-value {
  color: #000000;
}

.product-specification-ctn a p {
  margin: 10px 0 0;
  font-size: 14px;
  color: #dc4d04;
  text-decoration: underline;
}

.sellers-profile-ctn {
  border: 1px solid #dddddd;
  border-radius: 10px;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 30px 10px;
}

.sellers-profile-ctn .sellers-img-ctn {
  align-items: center;
  justify-content: center;
  display: flex;
}

.sellers-img-ctn img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 10px;
}

.sellers-img-ctn .user-area-art {
  width: 80px;
  height: 75px;
  border-radius: 50%;
  border: 3px solid #f9f9f9;
  background: #282828;
  color: #bed3ef;
  font-size: 40px;
  font-weight: 600;
  padding: 7px 16px;
  margin: auto;
}

.sellers-profile-ctn .sellers-company h2 {
  font-size: 18px;
  font-weight: 700;
  color: #282828;
}

.sellers-profile-ctn .sellers-company p {
  font-size: 16px;
  font-weight: 400;
  color: #282828;
}

/* .seller-message-ctn {
  display: flex;
} */

.sellers-profile-ctn > p {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 70px;
}

.sellers-profile-ctn p a {
  color: #dc4d04;
  text-decoration: underline !important;
  cursor: pointer;
}
.sellers-profile-ctn .seller-message-ctn button {
  font-size: 0.9rem;
  border: 1px solid #dc4d04;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: 0px !important;
  border-radius: 4px;
  padding: 10px 10px !important;
  margin: 0 10px;
}

.sellers-profile-ctn .seller-message-ctn .quote {
  color: #ffffff;
  background: #dc4d04;
}

.sellers-profile-ctn .seller-message-ctn .message {
  color: #282828;
  background: transparent;
}

.product-description-wrapper {
  border-bottom: 1px solid #dddddd;
}

.production-specification-title h2 {
  border-right: 1px solid #dddddd;
  padding-right: 20px !important;
}

.production-description-title {
  padding-left: 20px !important;
}

.production-specification-title,
.production-description-title {
  cursor: pointer;
}

.production-specification-title h2,
.production-description-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #282828;
  padding: 0 0 0px;
}

.product-description-wrapper .active {
  border-bottom: 4px solid #0064e5;
  border-radius: 3px;
}

.product-description-wrapper .active h2 {
  font-weight: 700;
}

.review-star-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}

.reviews-star-ctn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.reviews-star-ctn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.review-star-wrapper a {
  white-space: nowrap;
  color: #03479f !important;
  transition: all 0.1s ease-out;
}

.review-star-wrapper a:hover {
  text-decoration: underline !important;
}

@media (max-width: 1280px) {
  .product-detail-ctn .product-name {
    font-size: 20px;
  }

  .product-price {
    font-size: 12px;
  }
  .product-price .p-currency {
    font-size: 10px;
  }
  .product-detail-ctn .product-price .p-unit {
    font-size: 10px;
  }
  .product-specification-ctn .product-specification-title,
  .product-specification-ctn
    .product-specification-content
    .product-specification-list
    p {
    font-size: 12px;
  }
  .product-specification-ctn a p {
    font-size: 11px;
  }

  .sellers-profile-ctn .seller-message-ctn button {
    font-size: 0.6rem;
    padding: 8px 10px !important;
    margin: 0 8px;
  }
}

@media (max-width: 950px) {
  .sellers-profile-ctn {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .mp-wrap .main-product-pic {
    width: 100%;
  }

  .mp-wrap .product-thumbnail {
    width: 100%;
  }

  .mp-wrap .product-thumbnail .pt-box {
    width: 80px;
    height: 75px;
    margin-right: 4px;
    margin-bottom: 4px;
    background-color: #f9f9f9;
  }

  .mp-wrap .product-thumbnail .pt-box:active {
    border: 2px solid #dc4d04;
  }

  .mp-wrap {
    margin-bottom: 40px;
  }

  .production-specification-title h2,
  .production-description-title h2 {
    font-size: 15px;
  }
}
