#product-description {
  padding: 50px 0 0;
  background-color: #f9f9f9;
}

.not-active {
  display: none;
}

.m-product-spec-box {
  margin: 25px 0;
}

.m-product-spec-box .spec-link {
  font-size: 0.8rem;
  color: #dc4d04;
  float: right;
  margin: 0;
  padding: 0 0 0;
}

.m-product-spec-box .mp-desc {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0 0 15px;
}

.m-product-spec-box table {
  width: 100%;
  font-size: 0.85rem;
  margin-bottom: 0;
}

.m-product-spec-box table tr {
  border-color: transparent !important;
}

.m-product-spec-box table tr td {
  padding: 12px 15px;
}

.m-product-spec-box table tr .mps-title {
  color: #5c5c5c !important;
}

.m-product-spec-box .cls-1 {
  fill: #ffffff;
  stroke: #282828;
  stroke-linecap: round;
}
