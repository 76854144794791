.payment-page {
  padding: 20px;
  background: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.payment-page-container {
  display: flex;
  gap: 2rem;
  max-width: 800px;
}

.payment-left {
  flex: 2;
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
}

.logos {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.logos img {
  width: 80px;
  height: auto;
  object-fit: contain;
}

.company-logo,
.payment-service-logo {
  width: 100px;
  height: auto;
}

.product-cost p {
  font-size: 14px !important;
  font-weight: 700;
}

.payment-right {
  flex: 1;
}

.payment-method {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.method-button {
  padding: 10px 20px;
  border: 1px solid #76101a;
  background-color: white;
  cursor: pointer;
}

.method-button.active {
  background-color: #76101a;
  color: white;
}

.card-details,
.bank-transfer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
}

.card-details input,
.bank-transfer select {
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.save-card {
  display: flex;
  align-items: center;
  gap: 5px;
}

.continue-button,
.proceed-button {
  padding: 10px;
  background-color: #76101a;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.poweredBy {
  display: flex;
  gap: 2px;
  align-items: center;
}

.poweredBy p {
  font-size: 12px;
  font-weight: 600;
}

.bank-transfer-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-header:hover {
  background-color: #f0f0f0;
}

.dropdown-content {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1;
}

.search-input {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bank-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.bank-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.bank-item:hover {
  background-color: #f0f0f0;
}

.bank-logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.account__number__wrapper {
  background: #dddddd;
  padding: 1rem 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
}

.account__content p {
  color: #76101a;
  font-weight: 300;
  font-size: 14px;
}

.account__content .__cont {
  color: #414040;
  font-weight: 600;
}

.__note {
  font-size: 12px;
  color: rgb(152, 5, 5);
}

.pay__loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #76101a;
}

@media (max-width: 750px) {
  .payment-page-container {
    padding-top: 2rem;
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .payment-left,
  .payment-right {
    width: 100%;
    min-height: min-content;
  }
}
