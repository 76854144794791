.current_item_wrapper {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  width: 100%;
  margin: 10px 0;
}

.current_item_wrapper .current_item_ctn {
  width: 80px;
  min-width: 80px;
  cursor: pointer;
}

.current_item_wrapper .current_item_ctn img {
  height: 80px;
  width: 100%;
  object-fit: cover;
}

.current_item_wrapper .current_item_ctn p {
  font-size: 14px;
  color: #282828;
}

.current_item_wrapper .current_item_ctn:hover p {
  color: #f76116;
}

.current_item_properties {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.current_item_properties h6 {
  font-weight: 600;
  font-size: 16px;
}

.current_item_properties .prop_wrapper {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.prop_wrapper .prop_content {
  padding: 6px 10px;
  border: 1px solid #9c9b9b;
  color: #9c9b9b;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.prop_wrapper .prop_content_active {
  border: 1px solid #282828;
  color: #282828;
  font-weight: bold;
}

.current_item_wrapper::-webkit-scrollbar {
  width: 0; /* Chrome, Safari, and Opera */
  height: 0; /* Chrome, Safari, and Opera */
}

.discount__price {
  font-size: 12px;
  text-decoration: line-through;
  opacity: 0.3;
}

@media (max-width: 990px) {
  .current_item_properties .prop_wrapper {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    padding: 0.5rem 0;
  }
}
