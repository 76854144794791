.bs-main-wrapper {
  background-color: #fff;
  padding-top: 20px;
}

.bs-main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
}

.bs-main-content .bs-title {
  font-size: 56px;
  font-weight: 700;
  line-height: 78px;
  text-transform: capitalize;
}

.bs-main-content .bs-title span {
  color: #dd4f05;
}

.bs-main-content .bs-paragraph {
  font-size: 18px;
  margin: 10px 0 20px;
}

.bs-button {
  padding: 10px 40px;
  color: #fff;
  background-color: #dd4f05;
  border: none;
  outline: none;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.bs-button:hover {
  background-color: rgba(221, 79, 5, 0.9);
}

.bs-main-img-ctn {
  width: 100%;
  overflow: hidden;
  margin: 20px 0;
}

.bs-main-img-ctn img {
  width: 100%;
  height: auto;
  border-radius: 20% 4px;
}

.ws-market-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ws-market-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-transform: capitalize;
}

.ws-market-title-ctn .ws-market-paragraph {
  font-size: 16px;
  line-height: 22px;
}

.ws-market-content-wrapper {
  margin: 30px 0;
}

.ws-market-content .ws-market-numbers {
  background-color: #000;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 38px;
  text-align: center;
}

.ws-market-content .ws-market-heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 20px 0 10px;
  text-transform: capitalize;
}

.ws-market-content .ws-market-content-p {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.bs-step-img-ctn {
  width: 100%;
  overflow: hidden;
  margin: 20px 0 40px;
}

.bs-step-img-ctn img {
  width: 100%;
  height: auto;
}

.steps-ctn {
  margin-top: 60px;
}

.steps-ctn li {
  font-size: 16px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 10px;
}

.steps-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bs-plan-ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.bs-plan-title-ctn h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 800;
  line-height: 45px;
}

.bs-plan-title-ctn p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.bs-plan-wrapper {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.bs-plan-wrapper .bs-plan-content {
  border: 1px solid rgba(40, 40, 40, 0.4);
  border-radius: 12px;
  margin: 0 30px 20px;
  padding: 20px;
  width: 360px;
}

.bs-main-wrapper .bs-plan-content .bs-plan-load {
  width: 20px;
  height: 20px;
  border: 3px solid #3f3ff5;
  border-top: 3px solid #b6b6f1;
  border-radius: 50%;
  margin-bottom: 10px;
}

.bs-plan-content .bs-plan-type h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #000000;
  margin-bottom: 1px;
}
.bs-plan-content .bs-plan-type p,
.bs-plan-content .bs-plan-price p {
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.35);
  margin-top: 0px;
}

.bs-plan-content .bs-plan-price {
  margin: 10px 0;
}

.bs-plan-content .bs-plan-price h3 {
  font-size: 26px;
  font-weight: 800;
  line-height: 32px;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 1px;
}

.bs-plan-content .bs-plan-price h3 span {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.bs-plan-button,
.bs-plan-button button {
  width: 100%;
}

.bs-plan-button button {
  font-size: 16px;
  font-weight: 800;
  margin: 10px 0;
  padding: 10px 0;
  border: none;
  color: #fff;
  background: #0064e5;
  border-radius: 8px;
}

.bs-plan-terms-ctn .bs-plan-terms-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}

.bs-plan-terms-ctn .bs-plan-terms-content .fa-times {
  color: rgba(242, 19, 19, 1);
}

.bs-plan-terms-ctn .bs-plan-terms-content .fa-check {
  color: rgba(51, 209, 57, 1);
}

.bs-plan-terms-ctn .bs-plan-terms-content p {
  margin-left: 20px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: rgba(106, 107, 108, 1);
}

.bs-plan-paragraph p {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.bs-plan-paragraph p span {
  font-weight: 900;
}

.comp-ctn {
  margin: 20px 0;
}

.comp-ctn h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
}

.comp-ctn .comp-wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}

.comp-ctn .comp-wrapper .comp-line {
  height: 70px;
  width: 8px;
  background-color: rgba(220, 77, 4, 1);
}

.comp-ctn .comp-wrapper p {
  margin-top: 0;
  padding-top: 0;
  margin: 0 20px;
  width: 80%;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}

.comp-ctn .comp-bottom {
  display: flex;
  align-items: center;
}

.comp-ctn .comp-bottom div {
  border: 1px solid #979797;
  padding: 4px 4px;
  border-radius: 50%;
}

.comp-ctn .comp-bottom div i {
  background-color: #979797;
  color: #fff;
  padding: 7px 8px;
  border-radius: 50%;
}

.comp-ctn .comp-bottom p {
  color: #979797;
  margin: 0 20px;
}

.bs-video-ctn {
  padding: 20px 0;
}

.bs-video-title-ctn {
  text-align: center;
}

.bs-video-title-ctn h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-transform: capitalize;
}

.bs-video-title-ctn p {
  font-weight: 400;
  font-size: 18px;
  color: rgba(40, 40, 40, 0.81);
}

.bs-video-wrapper {
  width: 100%;
  height: 400px;
  background-image: url(../../assets/img/nicee1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 50px 0;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bs-video-wrapper p {
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 10px 40px;
}

@media (max-width: 990px) {
  .steps-ctn {
    margin-top: 0;
  }
  .comp-ctn h2 {
    font-size: 25px;
    line-height: 27px;
  }
}

@media (max-width: 765px) {
  .bs-main-content .bs-title {
    font-size: 40px;
    line-height: 60px;
  }
}

@media (max-width: 490px) {
  .bs-main-content .bs-title {
    font-size: 30px;
    line-height: 40px;
  }

  .bs-main-content .bs-paragraph {
    font-size: 16px;
  }
  .bs-button {
    padding: 10px 30px;
    font-size: 12px;
  }
  .ws-market-title {
    font-size: 25px;
    line-height: 30px;
  }
  .ws-market-title-ctn .ws-market-paragraph {
    font-size: 14px;
    line-height: 20px;
  }
  .steps-ctn li {
    font-size: 14px;
    line-height: 22px;
  }

  .bs-plan-title-ctn h2 {
    font-size: 25px;
    line-height: 30px;
  }

  .bs-plan-title-ctn p {
    font-size: 12px;
    line-height: 15px;
  }
  .bs-plan-wrapper .bs-plan-content {
    width: 270px;
  }
  .bs-plan-content .bs-plan-type h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .bs-plan-content .bs-plan-type p,
  .bs-plan-content .bs-plan-price p {
    font-size: 10px;
    line-height: 12px;
  }
  .bs-plan-content .bs-plan-price h3 {
    font-size: 18px;
    line-height: 20px;
  }

  .bs-plan-content .bs-plan-price h3 span {
    font-size: 12px;
    line-height: 16px;
  }
  .bs-plan-button button {
    font-size: 12px;
  }
  .bs-plan-terms-ctn .bs-plan-terms-content p {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
  }

  .bs-plan-paragraph p {
    font-size: 10px;
    line-height: 14px;
  }
  .comp-ctn h2 {
    font-size: 20px;
    line-height: 27px;
  }

  .comp-ctn .comp-wrapper p {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }

  .comp-ctn .comp-bottom div {
    border: none;
  }

  .comp-ctn .comp-bottom div i {
    font-size: 12px;
    padding: 4px 5px;
  }

  .comp-ctn .comp-bottom p {
    font-size: 12px;
  }
  .bs-video-title-ctn h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .bs-video-title-ctn p {
    font-size: 14px;
  }

  .bs-video-wrapper {
    height: 300px;
  }
}
