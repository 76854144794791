.fixedPosition {
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 15px 25px rgba(0, 4, 74, 0.04);
  background-color: #fff;
  top: 0;
}

/* Top Bar */
.topbar {
  padding: 7px 0;
  background-color: #f9f9f9;
}

.topbar .tb-menu {
  list-style: none;
  display: inline-flex;
  margin: 0;
}

.topbar .tb-menu li .tb-dropdown-icon {
  width: 10px;
  margin-left: 5px;
}

.topbar .topbar-icon {
  width: 20px;
  margin-right: 10px;
}

.topbar .tb-menu li a {
  color: #282828;
  font-size: 0.8rem;
  padding-left: 25px;
}

.topbar .tb-menu li:last-child {
  position: relative;
}

.topbar .tb-menu li svg {
  width: 20px;
}

.topbar .tb-menu li span {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  background-color: #f8d4c2;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  top: -3px;
  right: -15px;
  font-size: 10px;
}

.topbar .tb-menu li a:hover {
  color: #dc4d04;
}

.checkout {
  position: relative;
  display: none;
}

.checkout span {
  position: absolute;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  height: 20px;
  font-size: 10px;
  top: -10px;
  right: 0px;
  text-align: center;
  border-radius: 50%;
  background-color: #f8d4c2;
}

.topbar p {
  margin: 0;
  vertical-align: middle;
}

.topbar p a {
  color: #282828;
  font-weight: 500;
  font-size: 0.8rem;
}

.topbar p a:hover {
  color: #dc4d04;
}

/* Navbar */

.navbar {
  padding: 15px 0;
  background-color: #ffffff;
  position: relative;
  top: 10px;
}

.nav-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  width: 120px;
}

.logo img {
  height: 50px;
}

.main-menu-icon-wrap {
  display: flex;
  justify-content: center;
  width: 100px;
  padding-right: 10px;
}

.main-menu-icon-wrap .main-menu-icon {
  margin: 0 auto;
}

.search {
  width: 51%;
  position: relative;
  margin: 0 auto;
}

.search input {
  width: 85%;
  height: 44px;
  padding-left: 25px;
  padding-right: 60px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #dc4d04;
  border-radius: 20px 0 0 20px;
  background: transparent;
  color: #282828;
  outline: none;
}

.search button {
  width: auto;
  height: 44px;
  position: absolute;
  top: 50%;
  right: 1px;
  translate: 0 -50%;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  padding: 0 25px;
  height: 44px;
  border-radius: 0;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background: #dc4d04;
}

.nav-menu {
  display: inline-flex;
  list-style: none;
  padding-left: 0;
  margin: 0;
  color: #282828;
}

.nav-menu li {
  font-size: 0.9rem;
  font-weight: 600;
}

.nav-menu li .navbar-btn {
  width: 120%;
  font-weight: 600;
  background-color: #dc4d04;
  border-color: #dc4d04;
}

.nav-menu li a {
  color: #282828;
  padding: 0 0 0 25px;
}

.nav-menu li span {
  margin-left: 5px;
}

.mobile-search {
  display: none;
}

.nav-icon {
  color: #282828;
}

/* Navbar dropdown */

.nav-menu li .dropdown-icon {
  width: 12px;
  margin-left: 7px;
}

.dropdown-menu {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 40px rgb(0 0 0 / 20%);
  /* list-style: none; */
  padding: 1.5rem 0 1.5rem;
  z-index: 2;
  transform: translateY(8%);
  border: none;
  top: 10px;
}

/* .drop-menu-menu {
    position: absolute;
    display: flex;
    left: 0px;
  } */

.dropdown-menu-wrapper {
  display: flex;
  flex-direction: column;
  width: 290px;
}

.dropdown-menu-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
}

.dropdown-menu-content:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown-menu-content .dropdown-menu-icon-ctn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-right: 40px; */
}

.dropdown-menu-content .dropdown-menu-icon-ctn img,
.dropdown-menu-content .dropdown-menu-icon-ctn i {
  width: 8px !important;
  height: 8px !important;
  font-size: 8px;
  color: rgba(40, 40, 40, 0.8);
}
.dropdown-menu-content .dropdown-menu-icon-ctn p {
  font-size: 14px;
  font-weight: 500;
  color: #282828;
  margin: 0 0 0 20px;
}

.dropdown-menu-icon-ctn-two i {
  color: rgba(40, 40, 40, 0.6);
  font-size: 12px;
}

.custom-top {
  top: 10px !important;
  right: -50px !important;
}

.dropdown-menu li {
  font-size: 0.95rem;
  margin-bottom: 15px;
}

.dropdown-menu li:last-child {
  margin-bottom: 0;
}

.dropdown-menu li a {
  padding: 3px 50px 3px 20px;
  color: #282828;
}

.dropdown-menu .dropdown-item .nav-icon {
  margin-right: 10px;
}

.dropdown-menu .dropdown-item:hover {
  background: #f9f9f9;
  font-weight: 500;
}

.dropdown-menu .dropdown-item span:hover {
  color: initial;
}

.dropdown-menu li span {
  font-size: 0.8rem;
  color: #282828;
  font-weight: 400;
}

.btn-language {
  padding-top: 0;
  text-decoration: none !important;
  color: #282828;
  font-size: 0.9rem;
  font-weight: 600;
}

.btn-language:hover {
  color: #282828;
}

.dropdown-menu {
  min-width: 5rem;
}

.dropdown-menu li {
  font-size: 0.75rem;
}

@media (width < 768px) {
  .navbar .nav-menu li span {
    display: none;
  }
  .navbar .nav-menu li:last-child {
    display: none;
  }
  .mobile-search {
    display: block;
  }
  .main-menu-icon-wrap {
    width: 60px;
    order: -1 !important;
    margin-right: 10px;
  }
  .main-menu-icon {
    margin-top: 5px !important;
  }
  .logo img {
    height: 40px;
    margin-top: -6px;
  }
  .topbar {
    display: none;
  }

  .custom-top {
    right: 0 !important;
  }

  .checkout {
    display: inline !important;
  }
}

@media (width < 992px) {
  .mobile-search {
    display: block;
  }
  .search {
    display: none;
  }
}
