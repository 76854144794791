footer {
  padding: 0 0;
  background-color: #1a1a1a;
}

.footer-top {
  padding: 20px 0;
  background-color: #0b2239;
}

.footer-top .ft-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0 20px 15px;
}

.footer-top .ft-wrap h4 {
  color: #ffffff;
  font-weight: 700;
  font-family: Mulish, sans-serif;
  font-size: 18px;
}

.footer-top .ft-wrap p {
  margin-bottom: 5px;
}

.footer-top .ft-wrap p {
  color: #ffffff;
  font-weight: 600;
  font-family: Mulish, sans-serif;
  font-size: 12px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.footer-top .ft-wrap p span {
  margin-right: 10px;
}

.footer-top .ft-wrap a {
  color: #ffffff;
  font-weight: 600;
  font-family: Mulish, sans-serif;
  font-size: 12px;
  transition: all 0.1s ease-in-out;
}

.footer-top .ft-wrap a:hover,
.footer-top .ft-wrap p:hover {
  color: #dd4f05;
}

.footer-btm {
  padding: 15px 0;
  background-color: #000000;
}

.footer-btm {
  padding: 20px 0;
}

.fb-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fb-wrap p {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  color: #ffffff;
  opacity: 0.6;
}

.fb-wrap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.fb-wrap ul li {
  display: inline-block;
  padding: 0;
  padding-left: 25px;
  font-size: 0.8rem;
}

.fb-wrap ul li a {
  color: #ffffff;
  opacity: 0.6;
}

.fb-wrap ul li a:hover {
  text-decoration: underline;
}

@media (width < 768px) {
  .fb-wrap ul {
    display: none;
  }

  .footer-top .ft-wrap h4 {
    font-size: 14px;
  }

  .footer-top .ft-wrap a {
    font-size: 10px;
  }

  .footer-top .ft-wrap p {
    margin-bottom: 0px;
  }
}
