/* src/OrderSuccess.css */

.order-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  margin: 0;
  padding: 0;
}

.order-success-card {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 40px;
  width: 80%;
  max-width: 500px;
  margin: 20px;
  font-family: "Arial", sans-serif;
}

.order-success-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.order-success-message {
  font-size: 18px;
  margin-bottom: 20px;
  color: #34495e;
}

.order-success-icon {
  font-size: 48px;
  color: #27ae60;
  margin: 20px 0;
}

.order-success-details {
  font-size: 16px;
  margin-bottom: 30px;
  color: #7f8c8d;
}

.order-success-button {
  background-color: #e45e1b;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.order-success-button:hover {
  background-color: #f57535;
  color: #ffffff;
}
