.payment-status-container {
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending-message {
  color: #555;
}

.success-message {
  color: green;
}

.failed-message {
  color: red;
}

.button-group {
  margin-top: 20px;
}

.success-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
}

.failure-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.success-message .home__link,
.pending-message .home__link {
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 6px;
  background-color: #dc4d04;
  margin-top: 8rem !important;
}

.__support {
  color: #dc4d04;
  font-size: 14px;
}
