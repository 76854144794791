#inquiry-form {
  padding: 50px 0;
  width: 100%;
}

.inq-form-wrap {
  border: 1px solid #dddddd;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #ffffff;
}

.quote-title {
  font-size: 24px;
  color: #282828;
  font-weight: 700;
  margin: 0 0 40px;
}

.modal-contact-info {
  background-color: #22272e;
  border-radius: 4px;
}

.modal-contact-info .contact-paragraph {
  padding: 20px 20px 16rem;
  border-bottom: 1px solid #ffffff;
}

.modal-contact-info .contact-paragraph p {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.contact-info .contact-name-ctn h2 {
  color: #ffffff !important;
  font-size: 18px;
  font-weight: 700;
}

.contact-info .contact-name-ctn p a {
  font-size: 14px;
  font-weight: 500;
  color: #4890ed;
  text-decoration: underline;
}

.contact-details {
  padding: 0 20px 3rem;
  margin: 0;
}

.contact-address {
  font-size: 16px;
  margin: 0 0 10px;
  color: #ffffff;
}

.contact-address .nav-icon {
  margin-right: 20px;
}

form {
  width: 100%;
  margin: 20px 0;
  font-size: 0.85rem;
}

form label {
  font-size: 0.85rem;
  font-weight: 400;
  margin: 0 0 10px;
}

form .custom-country-list input {
  padding: 3px 15px !important;
}

/* form input {
    padding: 10px 15px;
    font-size: 0.85rem;
  } */

form select {
  padding: 10px 15px;
  font-size: 0.85rem !important;
  height: 40.39px;
}

form select:focus-within,
form textarea:focus-within,
form .custom-input:focus-within,
form .price-form .form-select:focus-within {
  border-color: #dc4d04 !important;
}

form textarea {
  padding: 10px 15px;
  font-size: 0.85rem !important;
}

form .custom-input {
  width: 100%;
  padding: 0 !important;
}

form .custom-input .form-unit {
  margin: 11px 0 11px 10px;
  padding: 0 15px;
  font-size: 0.8rem;
  border-left: 1px solid #dddddd;
}

form .custom-style {
  border: none;
}

form .unitQuantity {
  border: none !important;
  border-left: 1px solid #dddddd !important;
  padding-right: 4px;
}

form .price-form .form-select {
  background-image: none !important ;
}

form button {
  color: #ffffff;
  font-size: 0.9rem;
  border: 1px solid #dc4d04;
  border-radius: 4px !important;
  box-shadow: none !important;
  outline: 0px !important;
  border-radius: 4px;
  padding: 10px 20px !important;
  margin: 0;
  background: #dc4d04;
}

.css-13cymwt-control {
  height: 40.39px !important;
}

.css-13cymwt-control .css-1fdsijx-ValueContainer {
  height: 100% !important;
}

.css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  height: 80% !important;
}

@media (max-width: 980px) {
  .modal-contact-info {
    margin-bottom: 40px;
  }
}

@media (max-width: 780px) {
  .modal-contact-info .contact-paragraph p,
  .contact-info .contact-name-ctn h2 {
    font-size: 15px;
  }
  .contact-info .contact-name-ctn p a {
    font-size: 12px;
  }
  .contact-address {
    font-size: 12px;
  }

  .inq-form-wrap {
    padding: 20px 20px;
  }
  .quote-title {
    font-size: 18px;
  }
}
